<template>
  <div class="container">
    <van-sticky>
      <div class="status-bar" :style="{ minHeight: barHeight ? barHeight + 'px' : '' }"></div>
      <div class="nav row-between-center">
        <div v-if="appTypeStr !== 'mini'" @click="appBack" class="back"><van-icon name="arrow-left" color="#fff" size="24" /></div>
        <div class="buttons flex-auto row-around-center">
          <div @click="changeTab(index)" v-for="item, index in buttons" :key="index"
            :class="['button', 'f24', active === index ? 'f32 fw6 active' : 'f26']">{{ item }}</div>
        </div>
      </div>
    </van-sticky>
    <div class="content">
      <div v-show="active === 0" class="poster-dom" id="posterDom0">
        <div class="img-bg">
          <img :src="shareBg" alt="">
          <div class="mask"></div>
        </div>

        <div class="main-content">
          <div class="user-part row-start-center">
            <img :src="recordDetail.userImg" alt="" class="avator flex-none">
            <div>
              <p class="p1 f26 fw6">{{ recordDetail.userName }}</p>
              <p class="p2 f24 grey">{{ recordDetail.lessonStartTime }}</p>
            </div>
          </div>
          <div class="data-part row-between-center">
            <div>
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.runtime | time }}</p>
                </div>
                <div class="f22 p3 grey">训练时长</div>
              </div>
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.kcal }}</p>
                  <p class="p2 f22">Kcal</p>
                </div>
                <div class="f22 p3 grey">消耗热量</div>
              </div>
            </div>
            <div>
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.jzkg }}</p>
                  <p class="p2 f22"> g</p>
                </div>
                <div class="f22 p3 grey">消耗脂肪</div>
              </div>
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.rateval }}</p>
                  <p class="p2 f22">%</p>
                </div>
                <div class="f22 p3 grey">训练百分比</div>
              </div>
            </div>
            <div class="data-item col-start-center">
              <img :src="recordDetail.foodImg" alt="" class="data-example">
              <div class="row-center-center data-like f22">≈消耗{{ recordDetail.foodName }}</div>
            </div>

          </div>
          <div class="rank-part row-start-center">
            <img :src="recordDetail.medalImg" alt="" class="rank-example">
            <p class="f24 grey row-start-center">热量消耗排名：<span class="f52 num puhui">{{ recordDetail.lessonRank }}</span>
            </p>
          </div>
          <div class="h-c-total row-between-center">
            <div>
              <p class="f26 white mb16">平均心率</p>
              <p class="f24 grey"><span class="BebasNeueBold f56 mr10 c-3">{{ recordDetail.xinlu }}</span>次/分</p>
            </div>
            <div>
              <p class="f26 white mb16">最大心率</p>
              <p class="f24 grey"><span class="BebasNeueBold f56 mr10 c-5">{{ recordDetail.maxxl }}</span>次/分</p>
            </div>
            <div>
              <p class="f26 white mb16">最小心率</p>
              <p class="f24 grey"><span class="BebasNeueBold f56 mr10 white">{{ recordDetail.minxl }}</span>次/分</p>
            </div>
          </div>
          <line-chart v-if="dtoList.length" :dtoList="dtoList" :pieces="pieces"></line-chart>
          <bar :record="recordDetail" />
          <logo :record="recordDetail" style="margin: 40px 0 0;"></logo>
        </div>
      </div>
      <div v-show="active === 1" class="poster-dom" id="posterDom1">
        <div class="img-bg v2">
          <img :src="shareBg" alt="">
          <div class="mask"></div>
        </div>

        <div class="main-content v2">
          <div class="user-part row-start-center">
            <img :src="recordDetail.userImg" alt="" class="avator flex-none">
            <div>
              <p class="p1 f26 fw6">{{ recordDetail.userName }}</p>
              <p class="p2 f24 grey">{{ recordDetail.lessonStartTime }}</p>
            </div>
          </div>
          <div class="data-part row-between-center">
            <div class="col-between-start">
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.runtime | time }}</p>
                </div>
                <div class="f22 p3 grey">训练时长</div>
              </div>
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.kcal }}</p>
                  <p class="p2 f22"> Kcal</p>
                </div>
                <div class="f22 p3 grey">消耗热量</div>
              </div>
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.microTotalConsumedKcal }}</p>
                  <p class="p2 f22"> Kcal</p>
                </div>
                <div class="f22 p3 grey">{{ recordDetail.month }}月累计消耗热量</div>
              </div>
            </div>
            <div class="col-between-start">
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.rateval }}</p>
                  <p class="p2 f22"> %</p>
                </div>
                <div class="f22 p3 grey">训练百分比</div>
              </div>
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.jzkg }}</p>
                  <p class="p2 f22"> g</p>
                </div>
                <div class="f22 p3 grey">消耗脂肪</div>
              </div>
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.cityMicroRank }}</p>
                </div>
                <div class="f22 p3 grey">{{ recordDetail.cityTitle }}排名</div>
              </div>
            </div>
            <div class="col-between-start">
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.xinlu }}</p>
                  <p class="p2 f22"> bpm</p>
                </div>
                <div class="f22 p3 grey">平均心率</div>
              </div>
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f28 BebasNeueBold">{{ recordDetail.foodName }}</p>
                </div>
                <div class="f22 p3 grey">消耗食物</div>
              </div>
              <div class="data-item">
                <div class="row-start-end">
                  <p class="p1 f52 BebasNeueBold">{{ recordDetail.totalRank }}</p>
                </div>
                <div class="f22 p3 grey">全超鹿排名</div>
              </div>
            </div>

          </div>

          <logo :record="recordDetail"></logo>
        </div>
      </div>
      <div v-if="appTypeStr !== 'mini'" @click="openBgList" :class="['change-button', 'f24', 'fw6', 'row-center-center', active === 1 ? 'v2' : '']">
        更换背景图</div>
      <img v-show="active === 0" :src="poster0" alt="" :class="['poster-img', appTypeStr === 'mini' ? 'mini' : '']">
      <img v-show="active === 1" :src="poster1" alt="" :class="['poster-img', appTypeStr === 'mini' ? 'mini' : '']">
    </div>

    <van-popup v-model="show" :overlay="false" round position="bottom" :lock-scroll="false">
      <div class="c-share">
        <div v-if="appTypeStr === 'mini'" class="cs-list row-center-center" style="opacity: 0.6;">注：长按图片保存至相册</div>
        <div v-else class="cs-list row-around-center">
          <div class="cl-img" v-for="(item) in posterItemList" :key="item.key" @click="shareFn(item)">
            <img :src="item.img" alt="" />
            <div>{{ item.text }}</div>
          </div>
        </div>
        <div class="cs-cancle-btn" @click="appBack">取消</div>
      </div>
    </van-popup>

    <van-popup v-model="bgShow">
      <div class="bg-list">
        <van-sticky>
          <div class="status-bar" :style="{ minHeight: barHeight ? barHeight + 'px' : '' }"></div>
          <div class="nav row-between-center">
            <div @click="cancelBg" class="back"><van-icon name="cross" color="#fff" size="24" /></div>
            <div class="title f32">选择超鹿背景图</div>
            <div class="button f24 fw6 row-center-center" @click="confirmBg">完成</div>
          </div>
        </van-sticky>

        <div class="photo-box flex flex-wrap">
          <div @click="checkPhoto(index)" class="photo-item" v-for="item, index in photoList" :key="index"
            :style="`background-image: url(${item.imgPath})`">
            <div v-show="checkActive !== index" class="check-item"></div>
            <van-icon class="check-item checked" v-show="checkActive === index" size="18" name="checked"
              color="#FFDE00" />
          </div>
          <div class="photo-item"></div>
        </div>

      </div>
    </van-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, getBarHeight, webAppShare, saveCreateImg } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import wx from 'weixin-js-sdk'
import html2canvas from 'html2canvas'
import logo from './share/logo'
import line from './components/Line'
import bar from './components/Bar'

export default {
  mixins: [userMixin],
  components: {
    logo,
    bar,
    'line-chart': line
  },
  data() {
    return {
      barHeight: getBarHeight(),
      buttons: ['训练数据', '数据照片'],
      active: 0,

      userInfo: { // 用户信息
        qrCode: '',
        nickName: '',
        headImg: '',
      },

      show: true,
      bgShow: false,
      posterItemList: [ // 当分享海报时展示
        {
          scene: 0,
          img: 'https://chaolucoachwebstatic.chaolu.com.cn/cl_wx/img/common/001.png',
          text: "微信好友",
          type: '2',
          key: 'posterfriend'
        },
        {
          scene: 1,
          img: 'https://chaolucoachwebstatic.chaolu.com.cn/cl_wx/img/common/002.png',
          text: "朋友圈",
          type: '2',
          key: 'postermessage'
        },
        {
          scene: '',
          img: 'https://chaolucoachwebstatic.chaolu.com.cn/cl_wx/img/common/005.png',
          text: "保存",
          type: '',
          key: 'save'
        },
      ],
      params: { // 分享的信息
        url: 'url',
        image: '', // 生成的图片
      },
      poster0: '',
      poster1: '',

      photoList: [],
      bgActive: '',
      checkActive: '',
      recordDetail: {},
      dtoList: [],
      pieces: []
    }
  },
  filters: {
    time(t) {
      let min = Math.floor(t / 60), s = t % 60
      return (min < 9 ? '0' + min : min) + '’' + (s < 9 ? '0' + s : s) + '’’'
    },
  },
  computed: {
    shareBg() { // 分享海报的背景图
      return this.bgActive === '' ? this.recordDetail.courseImg : this.photoList[this.bgActive].imgPath
    }
  },

  async mounted() {
    await this.$getAllInfo()
    initBack()
// this.appTypeStr = 'mini'
    this.getDetail()
    this.getPhotoList()
  },
  methods: {
    appBack: newAppBack,
    changeTab(index) {
      this.active = index

      if (this.appTypeStr === 'mini') {
        this.createImage()
      }
    },

    async createImage() {
      return new Promise((resolve, reject) => {
        // let vc = this.$refs.vc
        // newImg.src = await vc.getDataURL({
        //   pixelRatio: 3,
        //   excludeComponents: ['title'],
        // });
        this.$toast.loading({
          forbidClick: true,
          loadingType: 'spinner',
        })

        let img = new Image()
        img.src = this.shareBg

        img.onload = () => {
          const element = document.querySelector(`#posterDom${this.active}`)
          const opts = {
            backgroundColor: 'transparent', // transparent
            useCORS: true, // 允许跨域
            height: element.offsetHeight,
            width: element.offsetWidth,
            scale: 4,
            dpi: window.devicePixelRatio * 4
          }

          html2canvas(element, opts).then((canvas) => {
            let dataURL = canvas.toDataURL('image/png')

            this[`poster${this.active}`] = dataURL

            console.log(this.params.image);
            resolve()
            this.$toast.clear()
          })
        }




      })

    },

    // 点击分享
    async shareFn(item) {
      if (!this.params.url) {
        this.tips('分享参数不能为空')
        return
      }

      await this.createImage()

      let params = {
        scene: item.scene,
        ...this.params
      }

      params.image = this[`poster${this.active}`]

      switch (item.key) {
        case 'posterfriend': // 分享图片给好友
          webAppShare(item.type, params)
          break;
        case 'postermessage': // 分享图片到朋友圈
          webAppShare(item.type, params)
          break;
        case 'save': // 保存图片
          saveCreateImg(params.image);
          break;
      }

    },
    getDetail() {
      this.$toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const id = getParam().appointId
      this.$axios.post(`${this.baseURLApp}/micro/TrainingRecord/userDetailData`, { appointId: id }).then(res => {
        this.recordDetail = res.data
        this.dtoList = res.data.dtoList
        this.pieces = res.data.pieces
        this.$toast.clear()

        wx.miniProgram.postMessage({
          data: {
            type: 'share',
            shareImage: this.recordDetail.courseImg,
            shareTitle: '扫码加入班级一起练',
            shareUrl: `/pages/miniCampPages/classDetail/index?classId=${this.recordDetail.classId}`,
          },
        })

        if (this.appTypeStr === 'mini') {
          this.createImage()
        }
      })
    },
    getPhotoList() {
      this.$axios.post(`${this.baseURLApp}/shareImg/getShareImgList`, {}).then(res => {
        this.photoList = res.data
      })
    },
    openBgList() {
      this.bgShow = true
      this.checkActive = this.bgActive
    },
    checkPhoto(index) {
      this.checkActive = this.checkActive === index ? '' : index
    },
    cancelBg() {
      this.bgShow = false
    },
    confirmBg() {
      this.bgShow = false
      this.bgActive = this.checkActive
    },

  },

}
</script>
<style lang="less" scoped>
.container {
  * {
    box-sizing: border-box;
  }

  .status-bar {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    background-color: #000;
  }

  .nav {
    width: 100%;
    height: 110px;
    padding: 0 32px;
    background-color: #000;
    color: #fff;

    .back {
      width: 96px;
    }

    .button {
      color: rgba(255, 255, 255, .6);

      &.active {
        color: #fff;
      }
    }
  }

  .content {
    width: 100%;
    padding: 32px 32px 392px;
    background-color: #000;
    position: relative;
  }

  .poster-dom {
    width: 100%;
    background-color: #212225;
    // background-image: url(https://img.chaolu.com.cn/MINI/pintuan-poster.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% auto;

    border-radius: 12px 12px 12px 12px;
    position: relative;
    overflow: hidden;

    .img-bg {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      &.v2 {
        position: relative;
        .mask {
          background: linear-gradient(180deg, rgba(33, 34, 37, 0) 0%, rgba(33, 34, 37, 1) 100%);
        }
      }

      img {
        width: 99.6%;
        margin: 0 auto;
        display: block;
      }

      .mask {
        width: 100%;
        height: 101%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        background: linear-gradient(180deg, rgba(33, 34, 37, .7) 0%, rgba(33, 34, 37, 1) 100%);
      }
    }

    .user-part {
      .avator {
        width: 76px;
        height: 76px;
        border-radius: 50%;
        margin: 0 20px 0 0;
      }

      .p1 {
        max-width: 356px;
      }

      .p2 {
        margin: 6px 0 0;
      }
    }

    .h-c-total {
      margin-top: 28px;
      padding: 44px 0 16px;
    }

    .data-part {
      .data-item {
        margin: 42px 0 0;

        .p1 {
          transform: translateY(8px);
        }

        .p2 {
          color: #9AA1A9;
        }

        .p2 {
          margin: 0 0 0 4px;
        }

        .p3 {
          margin: 8px 0 0;
        }
      }

      .data-example {
        width: 174px;
        height: 174px;
      }

      .data-like {
        padding: 0 20px;
        height: 40px;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 60px;
        margin: 8px 0 0;
      }
    }

    .rank-part {
      margin: 48px 0 0;
      padding: 0 30px;

      .rank-example {
        width: 154px;
        margin: 0 50px 0 0;
      }

      .num {
        margin: 0 0 0 10px;
        transform: translateY(-6px);
        color: #FF9446;
      }
    }

    .main-content {
      width: 100%;
      height: 100%;
      padding: 40px 32px;
      position: relative;
      color: #fff;
    }
  }

  .main-content.v2 {
    margin: -150px 0 0;

    .data-part {
      width: 100%;
      height: 426px;
      margin: 42px 0 0;
      background-image: url(https://img.chaolu.com.cn/ACT/mini-records/border.png);
      background-size: 100% 100%;
      padding: 40px 38px 40px 32px;

      & > div {
        height: 100%;
        width: 28%
      }
      & > div:nth-child(1) {
        height: 100%;
        width: 40%
      }
      & > div:nth-child(2) {
        height: 100%;
        width: 32%
      }

      .data-item {
        margin: 0;
      }

      .data-like {
        padding: 0 20px;
        height: 40px;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 60px;
        margin: 8px 0 0;
      }
    }
    .rank-part {
      margin: 48px 0 0;

      .rank-example {
        width: 154px;
        margin: 0 50px 0 0;
      }

      .num {
        margin: 0 0 0 10px;
        transform: translateY(-6px);
      }
    }

  }

  .change-button {
    width: 160px;
    height: 64px;
    border: 2px solid rgba(255, 255, 255, 0.15);
    border-radius: 6px;
    color: #fff;
    position: absolute;
    top: 78px;
    right: 64px;

    &.v2 {
      top: unset;
      bottom: 1140px;
    }
  }

  .poster-img {
    width: 686px;
    position: absolute;
    left: 32px;
    top: 32px;
    z-index: -1;
    &.mini {
      z-index: 2;
    }
  }

  .c-share {
    width: 100%;
    border-radius: 24px 24px 0px 0px;
    background-color: #fff;
    box-sizing: border-box;
    position: relative;

    .share-img {
      width: 180px;
      height: 180px;
      position: absolute;
      right: 10px;
      top: -160px;
    }

    .cs-title {
      padding: 48px 0 0 32px;
      font-size: 32px;
      color: #242831;
      font-weight: bold;
    }

    .cs-list {
      padding: 48px 0;
      // justify-content: space-around;
      .cl-img {
        font-size: 24px;
        color: #9aa1a9;
        text-align: center;
        margin-left: 56px;

        &:first-child {
          margin-left: 24px;
        }

        img {
          width: 112px;
          display: block;
          margin: 0 auto;
        }

        div {
          margin-top: 8px;
        }
      }
    }

    .cs-cancle-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 36px 0;
      font-size: 32px;
      color: #242831;
      box-sizing: border-box;
      border-top: 1px solid #eeeeee;
    }
  }

  .van-popup {
    width: 100%;
  }

  .bg-list {
    width: 100%;
    height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .status-bar {
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);
      background-color: #000;
    }

    .nav {
      width: 100%;
      height: 110px;
      padding: 0 32px;
      background-color: #000;
      color: #fff;

      .back {
        width: 96px;
      }

      .button {
        padding: 0 24px;
        height: 48px;
        background: #FFDE00;
        border-radius: 4px;
        color: #242831;
      }
    }

    .photo-box {
      justify-content: space-between;
      padding: 24px 32px;

      .photo-item {
        width: 226px;
        max-width: 226px;

        height: 226px;
        margin: 0 0 4px;
        background-size: cover;
        background-position: center;
        position: relative;

        .check-item {
          width: 36px;
          height: 36px;
          background: rgba(0, 0, 0, 0.4);
          border: 2px solid #FFFFFF;
          position: absolute;
          right: 12px;
          top: 12px;
          border-radius: 50%;

          &.checked {
            background: none;
            border: none;
          }
        }
      }
    }
  }

  .grey {
    color: #C3C3C3;
  }

  .c-3 {
    color: #52D7A6;
  }

  .c-5 {
    color: #FE6675;
  }

}
</style>
